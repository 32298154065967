import { BUY_SELL_MAIN, CFD, CHART_RESOLUTION_MAIN, ETF, FX } from './index';

export const BUILDER_PAGES = {
  EXCHANGE_TYPE_PAGE: {
    ID: 0,
    NAME: 'exchangeType',
  },
  QUANTITY_TYPE_PAGE: {
    ID: 1,
    NAME: 'quantityType',
  },
  CURRENCY_TYPE_PAGE: {
    ID: 2,
    NAME: 'currencyType',
  },
  CONFIGURATION_PAGE: {
    ID: 3,
    NAME: 'configuration',
  },
  BUILDER_TYPE_PAGE: {
    ID: 4,
    NAME: 'builderType',
  },
  CHART_MAKE_INSTRUMENT_CHOICE_PAGE: {
    ID: 6,
    NAME: 'chartMakeInstrumentChoice',
  },
  CHART_MAKE_DRAW_PAGE: {
    ID: 7,
    NAME: 'chartMakeDraw',
  },
  CHART_MAKE_SUMMARY_PAGE: {
    ID: 8,
    NAME: 'chartMakeSummary',
  },
  BUILDERS_SHARE_SELECT_PAGE: {
    ID: 9,
    NAME: 'buildersShareSelect',
  },
  BUILDERS_SHARE_INPUT_PAGE: {
    ID: 10,
    NAME: 'buildersShareInput',
  },
  BUILDERS_SHARE_CONFIRM_PAGE: {
    ID: 11,
    NAME: 'buildersShareConfirm',
  },
  BUILDERS_CONFIGURATION_FLOW_TOP_PAGE: {
    ID: 12,
    NAME: 'buildersConfigurationFlowTop',
    PAGE: 0,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP1_PAGE: {
    ID: 13,
    NAME: 'buildersConfigurationFlowStep1',
    PAGE: 1,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP2_PAGE: {
    ID: 14,
    NAME: 'buildersConfigurationFlowStep2',
    PAGE: 2,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP3_PAGE: {
    ID: 15,
    NAME: 'buildersConfigurationFlowStep3',
    PAGE: 3,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP4_PAGE: {
    ID: 16,
    NAME: 'buildersConfigurationFlowStep4',
    PAGE: 4,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP5_PAGE: {
    ID: 17,
    NAME: 'buildersConfigurationFlowStep5',
    PAGE: 5,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP6_PAGE: {
    ID: 18,
    NAME: 'buildersConfigurationFlowStep6',
    PAGE: 6,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP7_PAGE: {
    ID: 19,
    NAME: 'buildersConfigurationFlowStep7',
    PAGE: 7,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP8_PAGE: {
    ID: 20,
    NAME: 'buildersConfigurationFlowStep8',
    PAGE: 8,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP9_PAGE: {
    ID: 21,
    NAME: 'buildersConfigurationFlowStep9',
    PAGE: 9,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP10_PAGE: {
    ID: 22,
    NAME: 'buildersConfigurationFlowStep10',
    PAGE: 10,
  },
  TECH_CURRENCY_TYPE_PAGE: {
    ID: 23,
    NAME: 'techBuilderTypePage',
  },
  TECH_BUILDER_PAGE: {
    ID: 24,
    NAME: 'techBuilderPage',
  },
};

export const TECH_BUILDER_FLOW_PAGES = {
  BUILDERS_CONFIGURATION_FLOW_TOP_PAGE: {
    ID: 0,
    NAME: 'buildersConfigurationFlowTop',
    PAGE: 0,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP1_PAGE: {
    ID: 1,
    NAME: 'buildersConfigurationFlowStep1',
    PAGE: 1,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP2_PAGE: {
    ID: 2,
    NAME: 'buildersConfigurationFlowStep2',
    PAGE: 2,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP3_PAGE: {
    ID: 3,
    NAME: 'buildersConfigurationFlowStep3',
    PAGE: 3,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP4_PAGE: {
    ID: 4,
    NAME: 'buildersConfigurationFlowStep4',
    PAGE: 4,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP5_PAGE: {
    ID: 5,
    NAME: 'buildersConfigurationFlowStep5',
    PAGE: 5,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP6_PAGE: {
    ID: 6,
    NAME: 'buildersConfigurationFlowStep6',
    PAGE: 6,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP7_PAGE: {
    ID: 7,
    NAME: 'buildersConfigurationFlowStep7',
    PAGE: 7,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP8_PAGE: {
    ID: 8,
    NAME: 'buildersConfigurationFlowStep8',
    PAGE: 8,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP9_PAGE: {
    ID: 9,
    NAME: 'buildersConfigurationFlowStep9',
    PAGE: 9,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP10_PAGE: {
    ID: 10,
    NAME: 'buildersConfigurationFlowStep10',
    PAGE: 10,
  },
  BUILDERS_CONFIGURATION_FLOW_STEP11_PAGE: {
    ID: 11,
    NAME: 'buildersConfigurationFlowStep11',
    PAGE: 11,
  },
};

export const BUILDER_EXCHANGE_TYPES = {
  FX: {
    ID: 0,
    NAME: FX,
  },
  ETF: {
    ID: 1,
    NAME: ETF,
  },
  CFD: {
    ID: 2,
    NAME: CFD,
  },
};

export const BUILDER_EXCHANGE_TYPE_TO_SERVICE_ID = Object.freeze({
  [BUILDER_EXCHANGE_TYPES.FX.ID]: FX,
  [BUILDER_EXCHANGE_TYPES.ETF.ID]: ETF,
  [BUILDER_EXCHANGE_TYPES.CFD.ID]: CFD,
});

export const BUILDER_ORDER_TYPES = {
  SINGLE: {
    ID: 0,
    NAME: 'Single',
  },
  MULTI: {
    ID: 1,
    NAME: 'Multi',
  },
  CHART_MAKE: {
    ID: 2,
    NAME: 'chart',
  },
  TECH: {
    ID: 4,
    NAME: 'tech',
  },
};

export const BUILDER_ORDER_TYPES_NAME_BY_ID = {
  [BUILDER_ORDER_TYPES.SINGLE.ID]: BUILDER_ORDER_TYPES.SINGLE.NAME,
  [BUILDER_ORDER_TYPES.MULTI.ID]: BUILDER_ORDER_TYPES.MULTI.NAME,
  [BUILDER_ORDER_TYPES.CHART_MAKE.ID]: BUILDER_ORDER_TYPES.CHART_MAKE.NAME,
  [BUILDER_ORDER_TYPES.TECH.ID]: BUILDER_ORDER_TYPES.TECH.NAME,
};

export const ENTRY_PRICE_VALUES = {
  DESIGNATION: { VALUE: '価格指定', ID: 0 },
  PREVIOUS_DAY: { VALUE: '前日終値', ID: 1 },
};

export const ENTRY_PRICE_OPTIONS = [
  { label: ENTRY_PRICE_VALUES.DESIGNATION.VALUE, id: ENTRY_PRICE_VALUES.DESIGNATION.ID },
  { label: ENTRY_PRICE_VALUES.PREVIOUS_DAY.VALUE, id: ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID },
];

export const ENTRY_PRICE_NAME_BY_ID = {
  [ENTRY_PRICE_VALUES.DESIGNATION.ID]: ENTRY_PRICE_VALUES.DESIGNATION.VALUE,
  [ENTRY_PRICE_VALUES.PREVIOUS_DAY.ID]: ENTRY_PRICE_VALUES.PREVIOUS_DAY.VALUE,
};

export const PREV_DAY_PRICE_INFO = (buySellSide) => {
  const isBuy = Number(buySellSide) === BUY_SELL_MAIN.BUY.ID;
  return `チャート上に表示される緑のラインは前日終値(${
    isBuy ? '買' : '売'
  })です。前日終値を基準に値幅分離れたところから注文を仕掛けます。`;
};

export const BUILDER_ORDER_CONFIGURATION_OPTIONS = {
  SINGLE: {
    SELECTED_SELL_BUY_ID: 'selectedSellBuyId',
    AMOUNT: 'amount',
    ENTRY_PRICE_TYPE_ID: 'entryPriceTypeId',
    ENTRY_PRICE_VALUE: 'entryPriceValue',
    ENTRY_PRICE_PIPS_VALUE: 'entryPricePipsValue',
    OCO_IS_CHECKED: 'ocoIsChecked',
    OCO_VALUE: 'ocoValue',
    PROFIT_MARGIN: 'profitMargin',
    STOP_LOSS_SPREAD_IS_CHECKED: 'stopLossSpreadIsChecked',
    STOP_LOSS_SPREAD: 'stopLossSpread',
    FOLLOW_VALUE_IS_CHECKED: 'followValueIsChecked',
    FOLLOW_VALUE: 'followValue',
    COUNTER_VALUE_IS_CHECKED: 'counterValueIsChecked',
    COUNTER_VALUE_TYPE_ID: 'counterValueTypeId',
    COUNTER_VALUE: 'counterValue',
    COUNTER_PRICE_VALUE: 'counterPriceValue',
  },
  MULTI: {
    SELECTED_SELL_BUY_ID: 'selectedSellBuyId',
    AMOUNT: 'amount',
    ENTRY_PRICE_TYPE_ID: 'entryPriceTypeId',
    ENTRY_PRICE_VALUE: 'entryPriceValue',
    ENTRY_PRICE_PIPS_VALUE: 'entryPricePipsValue',
    OCO_IS_CHECKED: 'ocoIsChecked',
    PROFIT_MARGIN: 'profitMargin',
    FOLLOW_VALUE_IS_CHECKED: 'followValueIsChecked',
    FOLLOW_VALUE: 'followValue',
    COUNTER_VALUE_IS_CHECKED: 'counterValueIsChecked',
    COUNTER_VALUE: 'counterValue',
    RANGE_SPREAD: 'rangeSpread',
    ITEMS_COUNT: 'itemsCount',
    STOP_LOSS_SPREAD_IS_CHECKED: 'stopLossSpreadIsChecked',
    STOP_LOSS_SPREAD: 'stopLossSpread',
    COUNTER_IS_FIXED: 'counterIsFixed',
  },
  TECH: {
    INDICATOR1: 'indicator1',
    INDICATOR2: 'indicator2',
    BAR_TYPE: 'barType',
    IS_ALL_SETTLEMENT_IS_CHECKED: 'isAllSettlementChecked',
    IS_ALL_SETTLEMENT: 'isAllSettlement',
    SELECTED_SELL_BUY_ID: 'selectedSellBuyId',
    AMOUNT_IS_CHECKED: 'amountChecked',
    AMOUNT: 'amount',
    PROFIT_MARGIN_IS_CHECKED: 'profitMarginChecked',
    PROFIT_MARGIN: 'profitMargin',
    FOLLOW_VALUE_IS_CHECKED: 'followValueIsChecked',
    FOLLOW_VALUE: 'followValue',
    COUNTER_VALUE_IS_CHECKED: 'counterValueIsChecked',
    COUNTER_VALUE: 'counterValue',
    RANGE_SPREAD_IS_CHECKED: 'rangeSpreadChecked',
    RANGE_SPREAD: 'rangeSpread',
    ITEMS_COUNT_IS_CHECKED: 'itemsCountChecked',
    ITEMS_COUNT: 'itemsCount',
    STOP_LOSS_SPREAD_IS_CHECKED: 'stopLossSpreadIsChecked',
    STOP_LOSS_SPREAD: 'stopLossSpread',
    COUNTER_IS_FIXED_CHECKED: 'counterIsFixedChecked',
    COUNTER_IS_FIXED: 'counterIsFixed',
  },
};

export const BUILDER_ORDER_CONFIGURATION_VALIDATED_OPTIONS = {
  SINGLE: [
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.AMOUNT,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.ENTRY_PRICE_PIPS_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.OCO_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.PROFIT_MARGIN,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.STOP_LOSS_SPREAD,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.FOLLOW_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.SINGLE.COUNTER_PRICE_VALUE,
  ],
  MULTI: [
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.AMOUNT,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ENTRY_PRICE_PIPS_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.PROFIT_MARGIN,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.FOLLOW_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.COUNTER_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.RANGE_SPREAD,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.ITEMS_COUNT,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.MULTI.STOP_LOSS_SPREAD,
  ],
  TECH: [
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.AMOUNT,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.PROFIT_MARGIN,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.FOLLOW_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.COUNTER_VALUE,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.RANGE_SPREAD,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.ITEMS_COUNT,
    BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.STOP_LOSS_SPREAD,
  ],
};

export const ORDER_SETTINGS_TABLE_TAB = {
  value: '注文設定',
  id: 0,
};

export const LOGIC_GROUP_TABLE_TAB = {
  value: 'ロジックグループ',
  id: 1,
};

// TODO CFD この一帯に関しては要確認
export const RANGE_SPREAD_STEP = 50;
export const RANGE_ETF_JPY_SPREAD_STEP = 100;
export const RANGE_ETF_NOT_JPY_SPREAD_STEP = 1;
export const RANGE_CFD_SPREAD_STEP = 100;
export const ITEMS_COUNT_STEP = 1;
export const ENTRY_PRICE_PAIRED_YEN_STEP = 0.1;
export const ENTRY_PRICE_NOT_PAIRED_YEN_STEP = 0.001;
export const ENTRY_PRICE_PIPS_STEP = 1;
export const PROFIT_MARGIN_STEP = 1;
export const STOP_LOSS_STEP = 1;
export const FOLLOW_STEP = 1;
export const COUNTER_STEP = 1;
export const FX_CFD_STEP = 10;
export const ETF_JPY_PRICE_STEP = 10;
export const ETF_NOT_JPY_PRICE_STEP = 0.01;
export const CFD_JPY_PRICE_STEP = 10;
export const CFD_PRICE_STEP = 10;
export const BUILDER_TECH_ETF_NOT_JPY_PRICE_STEP = 0.1;
export const ETF_JPY_PRICE_MIN = 10;
export const ETF_NOT_JPY_PRICE_MIN = 0.1;
export const CFD_PRICE_MIN = 10;

export const AMOUNT_PRECISION = 1;
export const AMOUNT_PRECISION_INTEGER = 0;
export const ITEMS_COUNT_PRECISION = 0;

export const MAX_ORDERS_COUNT = 100;

export const DEFAULT_ORDER_SETS_VALUE = 1;

export const BUILDER_FX_QUANTITY_MAP = {
  'ZAR_AP/JPY': 1,
  'NOK_AP/SEK': 1,
};
export const BUILDER_QUANTITY_MAPS = {
  [FX]: BUILDER_FX_QUANTITY_MAP,
  [ETF]: {},
  [CFD]: {},
};

export const BUILDER_ETF_DEFAULT_QUANTITY = 10;
export const BUILDER_FX_DEFAULT_QUANTITY = 0.1;
export const BUILDER_CFD_DEFAULT_QUANTITY = 0.1;
export const BUILDER_DEFAULT_QUANTITIES = {
  [FX]: BUILDER_FX_DEFAULT_QUANTITY,
  [ETF]: BUILDER_ETF_DEFAULT_QUANTITY,
  [CFD]: BUILDER_CFD_DEFAULT_QUANTITY,
};

// TODO CFD この一帯に関しては要確認
export const ORDER_NAME_MAX_LENGTH = 50;
export const QUANTITY_MAX_VALUE = 200;
export const STOP_LOSS_MAX_VALUE = -20;
export const CFD_STOP_LOSS_MAX_VALUE = -10;
export const STOP_LOSS_ETF_CFD_MAX_MULTIPLIER = 3;
export const BASE_PRICE_MIN_MULTIPLIER = 0.5;
export const BASE_PRICE_MAX_MULTIPLIER = 1.5;
export const BASE_PRICE_MAX_ETF_MULTIPLIER = 5;
export const COMMON_PIPS_MIN_VALUE = 5;
export const COMMON_PIPS_MAX_VALUE = -5;
export const RANGE_SPREAD_MIN_VALUE = 100;
export const ITEMS_COUNT_MIN_VALUE = 1;
export const ITEMS_COUNT_MAX_VALUE = 100;
export const STRATEGY_SETS_MIN_VALUE = 1;
export const STRATEGY_SETS_MAX_VALUE = 200;
export const PROFIT_MARGIN_FX_MULTIPLIER = 3;
export const PROFIT_MARGIN_ETF_CFD_MULTIPLIER = 2;
export const DEFAULT_VALUE_ETF_CFD_PRECISION = 0;
export const ETF_INSTRUMENT_WITH_QUANTITY_STEP_TEN = '1306.TKS/JPY';
export const CFD_MIN_VALUE = 1;

export const ORDER_SETTINGS_ADDING_ERROR_INFO = `注文設定に追加できる本数上限(100)を超えているため、追加できません。`;
export const QUANTITY_TYPE_OPTIONS = [
  { id: 0, label: 'シングルカスタム' },
  { id: 1, label: 'マルチカスタム' },
];

export const QUANTITY_TYPE_CONTENT = {
  [BUILDER_ORDER_TYPES.SINGLE.NAME]: {
    title: 'シングルカスタム',
    subTitle: '自動売買注文を１つずつ作成',
  },
  [BUILDER_ORDER_TYPES.MULTI.NAME]: {
    title: 'マルチカスタム',
    subTitle: '自動売買注文を複数本同時作成',
  },
};

export const BUILDER_TYPE_TAB_CREATE = 'CREATE';
export const BUILDER_TYPE_TAB_RELEASE = 'RELEASE';

export const TECH_BASE_PRICE_LITERAL = 'basePrice';
export const TECH_START_PRICE_LITERAL = 'スタート価格';

export const CONFIRM_MESSAGES = [
  '設定画面に戻ると追加したロジックグループはリセットされます。',
  'ロジックグループを追加する場合は「設定を追加」を押してください。',
];

export const TECH_CONFIRM_MESSAGES = [
  '設定画面に戻ると追加したロジックグループはリセットされます。',
  '現在のロジックグループ設定内容を保持する場合は「設定を変更」を押してください。',
];

export const BUILDER_TERM_OPTIONS = [
  { value: CHART_RESOLUTION_MAIN.HOURS_1.ID, label: '1時間' },
  { value: CHART_RESOLUTION_MAIN.HOURS_4.ID, label: '4時間' },
  { value: CHART_RESOLUTION_MAIN.HOURS_8.ID, label: '8時間' },
  { value: CHART_RESOLUTION_MAIN.DAYS_1.ID, label: '日' },
  { value: CHART_RESOLUTION_MAIN.WEEKS_1.ID, label: '週' },
  { value: CHART_RESOLUTION_MAIN.MONTH_1.ID, label: '月' },
];

export const INDICATOR1_BUY_TOOLTIP =
  '買シグナルの判断に使うテクニカル指標を選択します。<br>' +
  'パラメーター設定とシグナル発生条件は以下のとおりです。<br>' +
  '<br>' +
  '単純移動平均線<br>' +
  '【設定】短期移動平均：5 /長期移動平均：20<br>' +
  '【条件】短期が長期を下から上へ突き抜ける<br>' +
  'ボリンジャーバンド<br>' +
  '【設定】期間：20 / 標準偏差：2<br>' +
  '【条件】終値がボリンジャーバンドの+2σを下から上へ突き抜ける<br>' +
  '一目均衡表<br>' +
  '【設定】転換線の期間：9 / 基準線の期間：26<br>' +
  '【条件】転換線が基準線を下から上へ突き抜ける<br>' +
  'DMI<br>' +
  '【設定】DIの期間：14<br>' +
  '【条件】+DIが-DIを下から上へ突き抜ける<br>' +
  'MACD<br>' +
  '【設定】短期移動平均：12 / 長期移動平均：26 / シグナルの期間：9<br>' +
  '【条件】MACDラインがシグナルラインを下から上へ突き抜ける<br>' +
  'RSI<br>' +
  '【設定】期間：14 <br>' +
  '【条件】RSIが30以下<br>' +
  'ストキャスティクス<br>' +
  '【設定】K：9 / D：3 / Smooth：3<br>' +
  '【条件】%Kが20%以下かつ%Kが%Dを上回る<br>' +
  'モメンタム<br>' +
  '【設定】期間：10<br>' +
  '【条件】0以下から0を超過する';

export const INDICATOR1_SELL_TOOLTIP =
  '売シグナルの判断に使うテクニカル指標を選択します。<br>' +
  'パラメーター設定とシグナル発生条件は以下のとおりです。<br>' +
  '<br>' +
  '単純移動平均線<br>' +
  '【設定】短期移動平均：5 /長期移動平均：20<br>' +
  '【条件】短期が長期を上から下へ突き抜ける<br>' +
  'ボリンジャーバンド<br>' +
  '【設定】期間：20 / 標準偏差：2<br>' +
  '【条件】終値がボリンジャーバンドの-2σを上から下へ突き抜ける<br>' +
  '一目均衡表<br>' +
  '【設定】転換線の期間：9 / 基準線の期間：26<br>' +
  '【条件】転換線が基準線を上から下へ突き抜ける<br>' +
  'DMI<br>' +
  '【設定】DIの期間：14<br>' +
  '【条件】+DIが-DIを上から下へ突き抜ける<br>' +
  'MACD<br>' +
  '【設定】短期移動平均：12 / 長期移動平均：26 / シグナルの期間：9<br>' +
  '【条件】MACDラインがシグナルラインを上から下へ突き抜ける<br>' +
  'RSI<br>' +
  '【設定】期間：14 <br>' +
  '【条件】RSIが70以上<br>' +
  'ストキャスティクス<br>' +
  '【設定】K：9 / D：3 / Smooth：3<br>' +
  '【条件】%Kが80%以上かつ%Kが%Dを下回る<br>' +
  'モメンタム<br>' +
  '【設定】期間：10<br>' +
  '【条件】0以上から0未満になる';

export const INDICATOR1_TOOLTIP_BOLD_LINES = [
  '単純移動平均線',
  'ボリンジャーバンド',
  '一目均衡表',
  'DMI',
  'MACD',
  'RSI',
  'ストキャスティクス',
  'モメンタム',
];

export const IS_ALL_SETTLEMENT_TOOLTIP =
  '反対シグナル発生時は自動売買注文が稼働停止となり、未約定の新規注文が取消されます。<br>' +
  'ON：反対シグナル発生時は自動売買の稼働停止となり、その自動売買が保有していた建玉を成行決済します。<br>' +
  'OFF：反対シグナル発生時は自動売買の稼働停止のみ行い、その自動売買が保有していた建玉は決済されません。';

export const BUILDER_OVERVIEW_PLACEHOLDER = 'ビルダーの概要を入力してください';
export const FORBIDDEN_NASDAQ100_TRIPLE_BUY_ORDER = 'ﾅｽﾀﾞｯｸ100ﾄﾘﾌﾟﾙの買い注文を含むルールは対象外です。';
export const SHAREABLE_RULE_UPPER_LIMIT_ERROR_MSG_F = (count) => `シェア可能なルールは最大${count}個です。`;
export const SHAREABLE_RULE_LOWER_LIMIT_ERROR_MSG = '2個以上のルールを選択してください。';

export const SHARE_CANCEL_CONFIRMATION = 'シェアの審査をキャンセルします。よろしいですか？';
export const SHARE_CANCEL_TITLE = '審査キャンセル';
export const SHARE_CANCEL_ACTION = '審査をキャンセルする';
export const SHARE_STOP_CONFIRMATION = 'シェアを公開停止します。よろしいですか？';
export const SHARE_STOP_TITLE = 'シェア停止';
export const SHARE_STOP_ACTION = 'シェアを停止する';

export const BUILDER_REQUIRED_MARGIN_VALIDATION_MESSAGE = '発注可能額が不足しているため稼働できませんでした。';
export const TECHNICAL_BUILDER = 'テクニカルビルダー';

export const CONFIGURATION_FLOW_SUB_TITLE = {
  BUY_SELL: '売りと買い、どちらから始めますか？',
  PRICE_RANGE: 'レンジ幅はどの位置から設定しますか？',
  RANGE_WIDTH: '自動売買注文を設定するレンジ幅はどうしますか？',
  RANGE_WIDTH_TECH: '自動売買注文を設定する範囲はどうしますか？',
  ITEMS_COUNT: 'レンジ幅の範囲内には自動売買注文を何本設定しますか？',
  ITEMS_COUNT_TECH: 'レンジ幅の範囲内には自動売買注文を何本設定しますか？',
  QUANTITY: '自動売買注文の注文数量はどうしますか？',
  PROFIT_MARGIN: '自動売買注文の利益確定はどうしますか？',
  STOP_LOSS: '自動売買注文の損失確定はどうしますか？',
  FOLLOW: '決済後に出す新規注文はどうしますか？①',
  COUNTER: '決済後に出す新規注文はどうしますか？②',
  BAR_TYPE: 'どの足種のチャートで売買シグナルを設定しますか？',
  INDICATOR: '売買シグナルの判断にはどのテクニカルを使いますか？',
  ALL_SETTLE: '反対シグナル発生時に保有している建玉はどうしますか？',
};

export const BUILDER_CONFIGURATION_FLOW_SUB_TITLE = {
  0: '設定の流れ',
  1: CONFIGURATION_FLOW_SUB_TITLE.BUY_SELL,
  2: CONFIGURATION_FLOW_SUB_TITLE.PRICE_RANGE,
  3: CONFIGURATION_FLOW_SUB_TITLE.RANGE_WIDTH,
  4: CONFIGURATION_FLOW_SUB_TITLE.ITEMS_COUNT,
  5: CONFIGURATION_FLOW_SUB_TITLE.QUANTITY,
  6: CONFIGURATION_FLOW_SUB_TITLE.PROFIT_MARGIN,
  7: CONFIGURATION_FLOW_SUB_TITLE.STOP_LOSS,
  8: CONFIGURATION_FLOW_SUB_TITLE.FOLLOW,
  9: CONFIGURATION_FLOW_SUB_TITLE.COUNTER,
};

export const TECH_CONFIGURATION_FLOW_SUB_TITLE = {
  0: '設定の流れ',
  1: CONFIGURATION_FLOW_SUB_TITLE.BUY_SELL,
  2: CONFIGURATION_FLOW_SUB_TITLE.BAR_TYPE,
  3: CONFIGURATION_FLOW_SUB_TITLE.INDICATOR,
  4: CONFIGURATION_FLOW_SUB_TITLE.ALL_SETTLE,
  5: CONFIGURATION_FLOW_SUB_TITLE.RANGE_WIDTH_TECH,
  6: CONFIGURATION_FLOW_SUB_TITLE.ITEMS_COUNT_TECH,
  7: CONFIGURATION_FLOW_SUB_TITLE.QUANTITY,
  8: CONFIGURATION_FLOW_SUB_TITLE.PROFIT_MARGIN,
  9: CONFIGURATION_FLOW_SUB_TITLE.STOP_LOSS,
  10: CONFIGURATION_FLOW_SUB_TITLE.FOLLOW,
  11: CONFIGURATION_FLOW_SUB_TITLE.COUNTER,
};

export const CONFIGURATION_FLOW_TITLE = {
  BUY_SELL: '売買',
  BUY_SELL_SIGNAL: '売買シグナル',
  PRICE_RANGE: 'スタート価格',
  RANGE_WIDTH: 'レンジ幅',
  ITEMS_COUNT: '本数',
  QUANTITY: {
    [FX]: '数量(万)',
    [ETF]: '数量(口)',
    [CFD]: '数量(Lot)',
  },
  PROFIT_MARGIN: '利確幅',
  STOP_LOSS: '損切幅',
  FOLLOW: 'フォロー値',
  COUNTER: 'カウンター値',
  BAR_TYPE: '足種',
  INDICATOR: 'インジケーター',
  ALL_SETTLE: '反対シグナル発生時に決済するか',
};

export const BUILDER_CONFIGRATION_FLOW_MAIN_TITLE = {
  ORDER: '新規注文の条件設定',
  SETTLEMENT: '決済注文の条件設定',
  REENTRY: '再エントリーの条件設定',
  TECH: 'テクニカルロジックの条件設定',
};

export const BUILDER_CONFIGURATION_MENU1 = [
  ['STEP 1', CONFIGURATION_FLOW_SUB_TITLE.BUY_SELL],
  ['STEP 2', CONFIGURATION_FLOW_SUB_TITLE.PRICE_RANGE],
  ['STEP 3', CONFIGURATION_FLOW_SUB_TITLE.RANGE_WIDTH],
  ['STEP 4', CONFIGURATION_FLOW_SUB_TITLE.ITEMS_COUNT],
  ['STEP 5', CONFIGURATION_FLOW_SUB_TITLE.QUANTITY],
];

export const BUILDER_CONFIGURATION_MENU2 = [
  ['STEP 6', CONFIGURATION_FLOW_SUB_TITLE.PROFIT_MARGIN],
  ['STEP 7', CONFIGURATION_FLOW_SUB_TITLE.STOP_LOSS],
];

export const BUILDER_CONFIGURATION_MENU3 = [
  ['STEP 8', CONFIGURATION_FLOW_SUB_TITLE.FOLLOW],
  ['STEP 9', CONFIGURATION_FLOW_SUB_TITLE.COUNTER],
];

export const TECH_BUILDER_CONFIGURATION_MENU1 = [
  ['STEP 1', CONFIGURATION_FLOW_SUB_TITLE.BUY_SELL],
  ['STEP 2', CONFIGURATION_FLOW_SUB_TITLE.BAR_TYPE],
  ['STEP 3', CONFIGURATION_FLOW_SUB_TITLE.INDICATOR],
  ['STEP 4', CONFIGURATION_FLOW_SUB_TITLE.ALL_SETTLE],
];

export const TECH_BUILDER_CONFIGURATION_MENU2 = [
  ['STEP 5', CONFIGURATION_FLOW_SUB_TITLE.RANGE_WIDTH_TECH],
  ['STEP 6', CONFIGURATION_FLOW_SUB_TITLE.ITEMS_COUNT_TECH],
  ['STEP 7', CONFIGURATION_FLOW_SUB_TITLE.QUANTITY],
];

export const TECH_BUILDER_CONFIGURATION_MENU3 = [
  ['STEP 8', CONFIGURATION_FLOW_SUB_TITLE.PROFIT_MARGIN],
  ['STEP 9', CONFIGURATION_FLOW_SUB_TITLE.STOP_LOSS],
];

export const TECH_BUILDER_CONFIGURATION_MENU4 = [
  ['STEP 10', CONFIGURATION_FLOW_SUB_TITLE.FOLLOW],
  ['STEP 11', CONFIGURATION_FLOW_SUB_TITLE.COUNTER],
];

export const BUILDER_MENU = {
  BUILDER: {
    MAIN_TITLE: 'ビルダー',
    SUB_TITLE: 'オリジナルのトレードルールをつくる',
    DETAIL: 'シンプルな操作で自分だけのトレードルールをつくることができます。各種設定の解説もご用意しています。',
  },
  CHART_MAKE: {
    MAIN_TITLE: 'チャートメイク',
    SUB_TITLE: 'チャートを描いてルールをつくる',
    DETAIL: 'チャートに未来の値動きを描くだけの直感的な操作でトレードルールが作成できます。',
  },
  TECH_BUILDER: {
    MAIN_TITLE: 'テクニカルビルダー',
    SUB_TITLE: 'テクニカル指標を使ってルールをつくる',
    DETAIL: '移動平均線などのテクニカル指標のシグナルに応じて稼働するルールを作成することができます。',
  },
};
